import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { initializeDataDog } from './setupDataDog';
import queryClient from './remote-state/queryClient';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store/store';
import Theme from './features/theme';

if (process.env.NODE_ENV !== 'development') {
  initializeDataDog();
}
const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <Theme>
          <CssBaseline />
          <App />
        </Theme>
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
);

//If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
