import { useQuery } from '@tanstack/react-query';
import { getContext } from '../services/platformIntegrationService';
import { hasLocalStorageItem } from '../common/utils/utils';

export const useContextQuery = () => ({
  ...useQuery({
    queryKey: ['contextQueryKey'],
    queryFn: () => getContext(),
    enabled: hasLocalStorageItem('account_id'),
  })
});