import { datadogRum } from '@datadog/browser-rum';
import { APP_CONSTANTS } from 'constants';

const fetchConfig = async (configUrl, configKey = null) => {
  let config = {};
  try {
    const response = await fetch(configUrl);
    const data = await response?.json();
    if (data) {
      config = configKey ? data?.[configKey] : data;
    }
  } catch (e) {
    console.log(`Failed fetching ${configKey ?? 'data'} from ${configUrl}`, e);
  }

  return config;
};

export async function initializeDataDog() {
  const { version, target: env } = await fetchConfig('/spaces/about.json');
  const { DATADOG_API_KEY: clientToken, SPACES_APPLICATION_TOKEN: applicationId } = await fetchConfig(
    '/spaces/datadog-api.json',
  );

  const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);

  if (!version || !applicationId) return;

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'spaces-client',
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    enableExperimentalFeatures: ["feature_flags"],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [(url) => url.includes('.jsp'), (url) => url.includes('spaces')],
  });

  if (accountId) {
    datadogRum.setGlobalContextProperty('accountId', accountId);
  }
}
