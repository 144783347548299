export interface Service<T = unknown> {
  destroy?: () => void;
  element?: HTMLElement | null;
  container?: HTMLElement | null;
  removeEventListeners?: () => void;
  [key: string]: T | (() => void) | HTMLElement | null | undefined;
}

export interface ServiceRegistryInstance {
  services: Map<string, Service>;
  destroyed: boolean;
  register: (id: string, service: Service) => void;
  getService: (id: string) => Service;
  cleanupService: (id: string) => void;
  cleanup: () => void;
}

/**
 * ServiceRegistry manages the lifecycle of services and ensures proper cleanup
 * to prevent memory leaks from controller services
 */
class ServiceRegistry implements ServiceRegistryInstance {
  private static instance: ServiceRegistry;
  public services: Map<string, Service> = new Map<string, Service>();
  public destroyed = false;

  constructor() {
    if (!ServiceRegistry.instance) {
      ServiceRegistry.instance = this;
    }
    return ServiceRegistry.instance;
  }

  /**
   * Register a service with the registry
   * @param {string} id - Unique identifier for the service
   * @param {Service} service - Service instance to register
   * @throws {Error} If registry is destroyed or service is invalid
   */
  public register(id: string, service: Service): void {
    if (this.destroyed) {
      throw new Error('ServiceRegistry has been destroyed');
    }

    if (this.services.has(id)) {
      console.warn(`Service with id ${id} already registered. Cleaning up old instance.`);
      this.cleanupService(id);
    }

    if (!service || typeof service !== 'object') {
      throw new Error('Invalid service instance');
    }

    this.services.set(id, service);
  }

  /**
   * Retrieve a registered service
   * @param {string} id - Service identifier
   * @returns {Service} The registered service instance
   * @throws {Error} If service is not found
   */
  public getService(id: string): Service {
    const service = this.services.get(id);
    if (!service) {
      throw new Error(`Service ${id} not found`);
    }
    return service;
  }

  /**
   * Clean up a specific service
   * @param {string} id - Service identifier
   */
  public cleanupService(id: string): void {
    const service = this.services.get(id);
    if (service) {
      // Call destroy method if it exists
      if (typeof service.destroy === 'function') {
        service.destroy();
      }

      // Clean up DOM references if they exist
      if (service.element) {
        service.element = null;
      }
      if (service.container) {
        service.container = null;
      }

      // Clean up event listeners
      if (typeof service.removeEventListeners === 'function') {
        service.removeEventListeners();
      }

      this.services.delete(id);
    }
  }

  /**
   * Clean up all registered services
   */
  public cleanup(): void {
    this.services.forEach((_, id) => {
      this.cleanupService(id);
    });
    this.destroyed = true;
  }

  /**
   * Reset the registry state (for testing purposes only)
   */
  public reset(): void {
    this.services.clear();
    this.destroyed = false;
  }
}

// Export singleton instance
const serviceRegistry = new ServiceRegistry();
if (process.env.NODE_ENV !== 'test') {
  Object.freeze(serviceRegistry);
}

export default serviceRegistry;
