import { axiosInstance } from './axiosConfig';

export const deleteAttachment = (attachmentId) =>
  axiosInstance.delete(`attachments/${attachmentId}`).then((response) => response.data);

// TODO: Use head request to avoid returning the file in response body
export const checkAttachmentExistence = (url) =>
  axiosInstance
    .get(url, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
    .then((response) => response.status >= 200 && response.status < 300);
