import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSwitchLangauge, selectRB, selectSettingLanguage, toggleSwitchHeadersLanguage } from 'store/userSlice';
import { useRouter } from '@tanstack/react-router';
import { QUERIES_KEYS } from 'constant';
import { APP_CONSTANTS } from 'constants/app';
import { getCookie } from 'common/utils/utils';
import { getApplicationData } from 'services/applicationService';
import { isLocalHost } from 'services/localhost';
import { useResourceBundle } from './userServiceHooks';

/**
 * Collator instance for consistent string comparison with numeric sorting
 * @type {Intl.Collator}
 */
const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

/**
 * Sorts an array of objects by a specified key using the collator
 * @param {Array<any>} array - The array to sort
 * @param {string} key - The key to sort by
 * @returns {Array<any>} A new sorted array, or empty array if input is falsy
 */
export const collatorCompare = (array, key) =>
  array ? [...array].sort((a, b) => collator.compare(a[key], b[key])) : [];

/**
 * Mapping of query keys to their corresponding data transformation handlers
 * Each handler processes a specific part of the application data
 * @type {Object.<string, function(Object): any>}
 */
const dataKeyHandlers = {
  [QUERIES_KEYS.GENERAL_SETTINGS]: (applicationData) => applicationData.generalSettings || {},
  [QUERIES_KEYS.RESOURCE_BUNDLE]: (applicationData) => applicationData.resourceBundle?.resourceBundleMap || {},
  [QUERIES_KEYS.IS_EXTERNAL_MODE]: (applicationData) => applicationData.isExternalMode ?? true,
  [QUERIES_KEYS.ADMINISTRATORS]: (applicationData) => collatorCompare(applicationData.administrators, 'userName'),
  [QUERIES_KEYS.BULK_ACTIONS_LIMIT]: (applicationData) => applicationData.bulkActionsLimit || 0,
  [QUERIES_KEYS.EDITION]: (applicationData) => applicationData.edition,
  [QUERIES_KEYS.GROUPS]: (applicationData) => collatorCompare(applicationData.groups, 'groupName'),
  [QUERIES_KEYS.MENU_ITEMS]: (applicationData) => {
    if (!applicationData.menuItems) return {};
    return { menu: applicationData.menuItems.menu, userOptions: applicationData.menuItems.userOptions };
  },
  [QUERIES_KEYS.IS_AI_ENABLED]: (applicationData) => applicationData.isAiEnabled,
  [QUERIES_KEYS.ENABLED_SWITCH_UI]: (applicationData) => applicationData.enabledSwitchUi,
  [QUERIES_KEYS.DEFAULT_UI]: (applicationData) => applicationData.defaultUi,
};

/**
 * Retrieves and transforms application data based on the provided key
 * @param {string} selectKey - The key identifying which part of the application data to retrieve
 * @param {Object} applicationData - The complete application data object
 * @returns {any} The transformed data for the specified key, or the complete application data if no handler exists
 */
export const getDataByKey = (selectKey, applicationData) => {
  const handler = dataKeyHandlers[selectKey];
  return handler ? handler(applicationData) : applicationData;
};

/**
 * React Query hook for fetching and managing application data
 * @param {string} selectKey - The key identifying which part of the application data to retrieve
 * @param {Object} options - Configuration options for the query
 * @param {boolean} [options.enabled] - Whether the query should automatically run
 * @param {boolean} [options.forceCall] - Force the query to run regardless of session state
 * @returns {import('@tanstack/react-query').UseQueryResult} The query result object
 */
export const useApplicationData = (selectKey, options = {}) => {
  const sessionId = getCookie(APP_CONSTANTS.SESSION_ID);
  const { enabled, forceCall, ...rest } = options;

  return useQuery({
    queryKey: [QUERIES_KEYS.APPLICATION_DATA],
    queryFn: getApplicationData,
    placeholderData: {},
    enabled: (isLocalHost() || !!sessionId || forceCall) && options.enabled !== false,
    select: (applicationData) => getDataByKey(selectKey, applicationData),
    ...rest,
  });
};

/**
 * Hook for managing resource bundle texts with language switching support
 * Handles two scenarios:
 * 1. Initial sign-in: Language is determined by API call or user data
 * 2. Runtime: Language changes through settings
 *
 * @returns {Object} Resource bundle texts for the current language
 *
 * State management:
 * - Uses Redux for language state and resource bundle storage
 * - Uses React Query for data fetching and caching
 * - Maintains local state for text updates
 *
 * Side effects:
 * - Updates query cache when language changes
 * - Resets language change flag after processing
 */
export function useRbTexts() {
  const LOGIN_PATH = '/spaces/login';
  const FORGOT_PASSWORD_PATH = '/spaces/forgot-password';
  const router = useRouter();
  const queryClient = useQueryClient();
  const isLoginPage = router.latestLocation.pathname.includes(LOGIN_PATH);
  const isForgotPasswordPage = router.latestLocation.pathname.includes(FORGOT_PASSWORD_PATH);
  const isLanguageChanged = useSelector((state) => state.user.changeLanguage);

  const settingsLoadedLanguage = useSelector(selectSettingLanguage);
  const loadedResourceBundleFromSettingsOrTranslations = useSelector(selectRB);
  const { data } = useApplicationData(undefined, { enabled: !isLoginPage });
  const { data: rbForLoginPage } = useResourceBundle({ enabled: isLoginPage });
  const dispatch = useDispatch();

  const dbLoadedLanguageResourcesFromDB = data?.resourceBundle?.resourceBundleMap;
  const languageResourcesToLoad =
    isLoginPage || isForgotPasswordPage ? rbForLoginPage : dbLoadedLanguageResourcesFromDB;

  const isLoadedResourceBundleFromSettingsOrTranslations =
    loadedResourceBundleFromSettingsOrTranslations && !(isLoginPage || isForgotPasswordPage);
  const resourceBundleMap = isLoadedResourceBundleFromSettingsOrTranslations
    ? loadedResourceBundleFromSettingsOrTranslations.resourceBundleMap
    : dbLoadedLanguageResourcesFromDB;

  const [texts, setTexts] = useState(resourceBundleMap);

  useEffect(() => {
    if (!loadedResourceBundleFromSettingsOrTranslations || !isLanguageChanged) {
      setTexts(languageResourcesToLoad);
    }

    if (loadedResourceBundleFromSettingsOrTranslations) {
      setTexts(loadedResourceBundleFromSettingsOrTranslations.resourceBundleMap);
    }
  }, [
    isLanguageChanged,
    settingsLoadedLanguage,
    loadedResourceBundleFromSettingsOrTranslations,
    languageResourcesToLoad,
    dispatch,
    texts,
  ]);

  if (!loadedResourceBundleFromSettingsOrTranslations) {
    if (languageResourcesToLoad) {
      return languageResourcesToLoad;
    }
    return {};
  }

  if (isLanguageChanged) dispatch(toggleSwitchHeadersLanguage(true));

  if (!texts || Object.keys(texts).length === 0) {
    return {};
  }

  if (!isLanguageChanged || isLoginPage || isForgotPasswordPage) {
    return texts;
  }

  queryClient.setQueryData([QUERIES_KEYS.RESOURCE_BUNDLE], (prev) => {
    if (prev) {
      prev.resourceBundleMap = texts;
    }
    return prev;
  });

  queryClient.setQueryData([QUERIES_KEYS.APPLICATION_DATA], (prev) => {
    if (prev) {
      prev.resourceBundle.resourceBundleMap = texts;
    }
    return prev;
  });

  if (isLanguageChanged) dispatch(toggleSwitchLangauge(false));

  return texts;
}
