import { QueryClient } from '@tanstack/react-query';

export const cacheTime = 1000 * 60 * 60 * 24; // 24 hours
export const throttleTime = 1000 * 2; // 2 seconds

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: 2,
    },
  },
});

export default queryClient;
