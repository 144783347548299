import { SourcePageEnum } from 'features/templateList/sourcePageEnum';
import { axiosInstance } from './axiosConfig';

export function getTemplateDataByTemplateId(templateId, source) {
  return axiosInstance
    .get(`templates/${templateId}${source ? `?source=${source}` : ''}`)
    .then((response) => response.data);
}

export function getOOTBTemplateBySrType(srType) {
  return axiosInstance.get(`templates/${srType}/ootb_templates`).then((response) => response.data);
}

export function createTemplate({ updateUser, templateObject }) {
  return axiosInstance
    .post(`templates?update_user=${encodeURIComponent(updateUser)}`, templateObject)
    .then((response) => response.data);
}

export function updateTemplate({ updateUser, templateObject }) {
  templateObject?.sections?.forEach((s) => {
    s?.fields?.forEach((f) => {
      if (f.fieldName === 'description') {
        if (typeof f.defaultValue === 'string' || !f.defaultValue) return;
        if (typeof f.defaultValue === 'object') {
          const arr = [];
          arr.push(f.defaultValue);
          f.defaultValue = arr;
        }
        f.defaultValue = JSON.stringify(f.defaultValue);
      }
    });
  });
  return axiosInstance
    .put(`templates?update_user=${encodeURIComponent(updateUser)}`, templateObject)
    .then((response) => response.data);
}

export function duplicateTemplate({ updateUser, templateId }) {
  return axiosInstance
    .post(`templates/duplicate/${templateId}?update_user=${encodeURIComponent(updateUser)}`)
    .then((response) => response.data);
}

export function deleteTemplate({ updateUser, templateId }) {
  return axiosInstance
    .delete(`templates/${templateId}?update_user=${encodeURIComponent(updateUser)}`)
    .then((response) => response.data);
}

export function setTemplateAsDefault({ updateUser, templateId }) {
  return axiosInstance
    .post(`sr/ticket_template/set_as_default/${templateId}?update_user=${encodeURIComponent(updateUser)}`)
    .then((response) => response.data);
}

export function getAvailableFields({ page = 0, search = '' }) {
  const queryPage = `?page=${page}${search ? `&filter=${search}` : ''}`;
  return axiosInstance.get(`/templates/available_fields${queryPage}`).then((response) => response.data);
}
export function getAvailableColumns({ search = '', limit = 50, page = 0 }) {
  const queryPage = `?filter=${search}&limit=${limit}${`&page=${page}`}`;
  return axiosInstance.get(`/template/available_fields_for_queue${queryPage}`).then((response) => response.data);
}

export function getTemplatesList({
  page,
  size,
  sort,
  columnFilters,
  globalFilter,
  defaultsFirst,
  sourcePage,
  excludeSubTypes = false,
}) {
  const queryParams = new URLSearchParams();
  queryParams.append('sourcePage', sourcePage !== undefined ? sourcePage : SourcePageEnum.TEMPLATES_PAGE);
  queryParams.append('page', page || 0);
  if (size) {
    queryParams.append('size', size);
  }
  queryParams.append('defaultsFirst', defaultsFirst || false);
  return axiosInstance
    .post(`/templates/getList?${queryParams.toString()}`, {
      sort,
      columnFilters,
      globalFilter,
      excludeSubTypes,
    })
    .then((response) => response.data);
}

export function getTemplatesListTotalSize({ columnFilters, sourcePage }) {
  const queryParams = new URLSearchParams();
  queryParams.append('sourcePage', sourcePage);
  return axiosInstance
    .post(`/templates/getListTotalSize?${queryParams.toString()}`, {
      columnFilters,
    })
    .then((response) => response.data);
}

export function getCategoryDrivenTemplate(primaryCategoryKey, secondaryCategoryKey, thirdLevelCategoryKey, srType) {
  return axiosInstance
    .get(`/templates/category/${primaryCategoryKey}/${secondaryCategoryKey}/${thirdLevelCategoryKey}/${srType}`)
    .then((response) => response.data);
}

export function getCategoryDrivenTemplateWithoutThirdLevel(primaryCategoryKey, secondaryCategoryKey, srType) {
  return axiosInstance
    .get(`/templates/category/${primaryCategoryKey}/${secondaryCategoryKey}/${srType}`)
    .then((response) => response.data);
}

export function getWorkflowDesignerUrl(templateId) {
  return axiosInstance.get(`template/${templateId}/workflow_designer_suffix`).then((response) => response.data);
}
