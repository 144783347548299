import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient, useQueries } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter, useRouterState } from '@tanstack/react-router';
import { get } from 'lodash';
import { useCategoryDrivenEnabled } from 'remote-state/accountSettingsServiceHooks';
import { QUERIES_KEYS, QUERY_STALE_TIME } from 'constant';
import { useApplicationData, collatorCompare } from 'remote-state/applicationHooks';
import { addUpdatingSR, removeUpdatingSR, updateEscalationPrompt } from 'store/srSlice';
import {
  selectedQueueTickets,
  selectPrefetchSrParams,
  selectSearchSrParams,
} from 'features/queue/slice';
import { getQueryKeyFromParams, getRequestFromParams } from 'features/queue/grid/helpers';
import { selectActiveUser } from 'store/userSlice';
import {
  createSR,
  getCategoryListAttributes,
  getColumnFilterList,
  getCustomValueList,
  getFieldAttributes,
  getSrById,
  getStatusListByValueClasses,
  getTicketLockStatus,
  getTicketPermissions,
  getTicketTemplateDataBySrType,
  lockSR,
  newSrFieldUpdate,
  postSearchSrRequest,
  unlockSR,
  updateSR,
  convertSR,
  getRecentlyCreatedSrs,
  archiveSR,
  duplicateSR,
} from 'services/ticketService';
import { getUserDetailsById, getUserDetailsByName } from 'services/userService';
import { isLocalHost } from 'services/localhost';
import { getUsersByGroup } from 'services/groups';
import {
  getCategoryDrivenTemplate,
  getCategoryDrivenTemplateWithoutThirdLevel,
  getTemplatesList,
} from 'services/templateService';
import { ITEM_TYPES } from 'features/resolutionPanel/middlePanel/relatedItems/constants';
import { setIsSaveInProgress, setIsSrSaved } from 'store/saveSrValidationSlice';
import { SR_CODE_TYPE_MAP, SR_TYPE_CODE_MAP, SR_TYPES } from 'features/TicketPanel/constants';
import { APP_CONSTANTS } from 'constants/app';
import { ROUTES_PATHS, TICKETS_OPEN_URL } from 'constants/index';
import { capitalizeFirstLetter, expandQueryKey, findValuesByIds } from 'common/utils/utils';
import { getIsExistingSr, getIsPreviewSr, shouldShowDeEscalationPrompt } from 'common/utils/srUtils';
import { convertFromClientField, convertToClientField } from 'common/utils/fieldUtils';
import { CATEGORIES } from 'features/header/categories/constants';
import { SourcePageEnum } from 'features/templateList/sourcePageEnum';
import { SESSION_STORAGE } from 'features/queue/constants';
import { SR_PANEL_CONSTANTS, STATUS_FIELD_ID } from 'features/srPanel/consts';
import useMemoizedQueries from 'common/utils/hooks/useMemoizedQueries';
import useTexts from 'features/queue/grid/useTexts';
import { useCategory } from 'store/CategoryContext';
import { getSuggestedCategoriesByText } from 'services/sysaiService';
import { CONSTANTS } from 'common/components/autoPopulateTicket/constants';
import { useGetTemplateById, useGetTemplateByIds, useTemplateData } from './templateHooks';
import { setToasterMessage } from '../store/globalSlice';
import { convertClientColumnConfig } from '../features/queue/utils';

//TODO: Update collator according to locale
const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

const options = {
  staleTime: 1000 * 60 * 15,
};

export function useClosureInformationList(queryObj) {
  const queryKey = expandQueryKey([QUERIES_KEYS.CLOSURE_INFORMATION], queryObj);
  return useQuery(queryKey, () => getCustomValueList('closureInformation', queryObj), {
    placeholderData: [],
    staleTime: 0,
    cacheTime: 0,
  });
}

export function useIsSrPage(forceSrPage) {
  const router = useRouter();

  return forceSrPage || router?.latestLocation?.pathname?.indexOf('ticket') >= 0;
}

export function useCategoryDrivenTemplate(srType) {
  const router = useRouter();
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const { state: catgoryState } = useCategory();
  const { selectedPrimaryCategory, selectedSecondaryCategory, selectedThirdLevelCategory } = catgoryState;
  const isCategoryDrivenEnabled = useCategoryDrivenEnabled()?.data?.data;
  const isNew = router.latestLocation.search.id === 'new';
  const allCategoriesSelectedInDrivenMode =
    isCategoryDrivenEnabled &&
    isNew &&
    !!selectedPrimaryCategory &&
    !!selectedSecondaryCategory &&
    !!(!isTripleCategory || selectedThirdLevelCategory);

  return useQuery(
    [
      'categoryDrivenTemplateData',
      selectedPrimaryCategory,
      selectedSecondaryCategory,
      selectedThirdLevelCategory,
      srType,
    ],
    () => {
      if (isTripleCategory) {
        return getCategoryDrivenTemplate(
          selectedPrimaryCategory,
          selectedSecondaryCategory,
          selectedThirdLevelCategory,
          srType,
        );
      }
      return getCategoryDrivenTemplateWithoutThirdLevel(selectedPrimaryCategory, selectedSecondaryCategory, srType);
    },
    {
      enabled: !!allCategoriesSelectedInDrivenMode && !!sessionStorage.getItem(SESSION_STORAGE.categoryIsSelected),
      ...options,
      staleTime: 100,
      onSettled: () => {
        sessionStorage.removeItem(SESSION_STORAGE.categoryIsSelected);
      },
    },
  );
}

export function useSrById(srId) {
  return useQuery([QUERIES_KEYS.SR_ID, String(srId)], () => getSrById(srId), {
    placeholderData: {},
    enabled: getIsExistingSr(srId),
  });
}

export function useSrByIds(srIds) {
  const queries = useQueries({
    queries: (srIds || []).map((srId) => ({
      queryKey: [QUERIES_KEYS.SR_ID, String(srId)],
      queryFn: () => getSrById(srId),
      enabled: getIsExistingSr(srId),
    })),
  });

  // Memoize the result processing
  const result = useMemo(() => {
    // Handle empty srIds case
    if (!srIds?.length) return [];

    const isLoading = queries.some((q) => q.isLoading);

    if (isLoading) return undefined;

    return queries.map((q) => q.data).filter(Boolean);
  }, [queries, srIds]);

  return result;
}

export function useTicketTemplateData(srTypeOrId, isConvertSrType = false, forceSrPage, disabled) {
  const isExisting = getIsExistingSr(srTypeOrId);
  const isSrPage = useIsSrPage(forceSrPage);
  const { data: existingSR } = useSrById(srTypeOrId);
  const templateDataBySrId = useGetTemplateById(existingSR?.ticketTemplate?.id, 'ticket');
  const templateDataBySrType = useQuery({
    queryKey: [QUERIES_KEYS.DEFAULT_TEMPLATE, srTypeOrId],
    queryFn: () => getTicketTemplateDataBySrType(srTypeOrId, 'ticket'),
    enabled: !!srTypeOrId && (isSrPage || isConvertSrType) && !isExisting && !disabled,
    select: useCallback(
      (template) => ({
        ...template,
        sections: template.sections.map((section) => ({
          ...section,
          sectionRows: section.sectionRows.map((sectionRow) => ({
            ...sectionRow,
            fields: sectionRow.fields.map((field) => convertToClientField(field, 'fieldId')),
          })),
        })),
      }),
      [],
    ),
    ...options,
  });
  return isExisting ? templateDataBySrId : templateDataBySrType;
}

export const buildHeaderDefaultValues = (template) => {
  const defaultValues = {};
  template?.header?.forEach((headerField) => {
    defaultValues[headerField.fieldName] = headerField.defaultValue;
  });
  return defaultValues;
};

export function useNewSR(template = {}, isFetched = false) {
  const userAccount = useSelector(selectActiveUser);
  const { isPlaceholderData, data } = template;
  const newSRObject = useMemo(() => {
    if (isPlaceholderData) {
      return {};
    }

    const headerDefaultValues = buildHeaderDefaultValues(data);

    return {
      id: null,
      assignee: headerDefaultValues.assignee,
      priority: headerDefaultValues.priority,
      status: headerDefaultValues.status,
      assignedGroup: headerDefaultValues.assignedGroup,
      title: headerDefaultValues.title,
      richTextFields: [],
      primaryCategory:
        headerDefaultValues[CATEGORIES.FIRST_LEVEL_CATEGORY_FIELD_KEY] === ''
          ? null
          : headerDefaultValues[CATEGORIES.FIRST_LEVEL_CATEGORY_FIELD_KEY],
      secondaryCategory:
        headerDefaultValues[CATEGORIES.SECOND_LEVEL_CATEGORY_FIELD_KEY] === ''
          ? null
          : headerDefaultValues[CATEGORIES.SECOND_LEVEL_CATEGORY_FIELD_KEY],
      thirdLevelCategory:
        headerDefaultValues[CATEGORIES.THIRD_LEVEL_CATEGORY_FIELD_KEY] === ''
          ? null
          : headerDefaultValues[CATEGORIES.THIRD_LEVEL_CATEGORY_FIELD_KEY],
      submitUser: userAccount?.username,
      srType: SR_CODE_TYPE_MAP[data?.srType || SR_TYPES.incident],
    };
  }, [isPlaceholderData, data, userAccount]);

  return {
    isFetched,
    data: newSRObject,
  };
}

export function useSR(forceSrId, forceSrType) {
  const routerState = useRouterState();
  const { search, pathname } = routerState.location;
  const sr = { id: undefined, srType: undefined, ...search };
  let srId = sr.id;
  let srType = sr.srType;
  if (forceSrId) {
    srId = forceSrId;
  }
  if (forceSrType) {
    srType = forceSrType;
  }
  const isPreviewSR = getIsPreviewSr(srId);
  const isExistingSR = getIsExistingSr(srId) && pathname !== ROUTES_PATHS.TEMPLATE_URL;
  const template = useTicketTemplateData(isExistingSR ? srId : srType, forceSrId != null);
  let previewTemplateOrIdFromStorage = sessionStorage.getItem(APP_CONSTANTS.PREVIEW_TEMPLATE_OR_ID_KEY) || null;
  const isTemplateIdFromStorage = previewTemplateOrIdFromStorage
    ? !Number.isNaN(Number(previewTemplateOrIdFromStorage))
    : null;
  const { data, isFetched } = useTemplateData(
    isTemplateIdFromStorage ? previewTemplateOrIdFromStorage : undefined,
    'ticket',
  );

  if (previewTemplateOrIdFromStorage) {
    if (isTemplateIdFromStorage) {
      previewTemplateOrIdFromStorage = data;
    } else {
      previewTemplateOrIdFromStorage = JSON.parse(previewTemplateOrIdFromStorage);
    }
  }
  const previewTemplate = previewTemplateOrIdFromStorage || template;
  const existingSR = useSrById(isExistingSR ? srId : undefined);
  const newSR = useNewSR(isPreviewSR ? previewTemplate : template, isFetched);
  const srResponse = !isExistingSR ? newSR : existingSR;
  return {
    srId,
    sr: srResponse,
    error: srResponse?.error?.response,
    template: isPreviewSR ? { ...template, data: previewTemplate } : template,
  };
}

export const useExistingSRs = (srIds) => {
  // Move hooks before any conditional returns
  const memoizedSrIds = useMemo(() => srIds, [srIds]);

  const srs = useSrByIds(memoizedSrIds);

  const templatesMap = useMemo(() => {
    if (!srs) return {};
    return srs.reduce((acc, sr) => {
      if (sr?.id && sr?.ticketTemplate?.id) {
        acc[sr.id] = sr.ticketTemplate.id;
      }
      return acc;
    }, {});
  }, [srs]);

  const templates = useGetTemplateByIds(Object.values(templatesMap ?? {}), 'ticket');

  const result = useMemo(() => {
    // Handle empty srIds case first
    if (!srs?.length || !templates?.length || !memoizedSrIds?.length) {
      return [];
    }

    const mappedResult = srs?.map((sr) => ({
      srId: sr?.id,
      sr,
      error: sr?.error?.response,
      template: templates?.find((t) => t.id === sr.ticketTemplate?.id),
    }));

    return mappedResult;
  }, [memoizedSrIds, srs, templates]);

  return { srsCombined: result, templatesMap };
};

export function useCreateNewSR(srId, srType) {
  const template = useTicketTemplateData(srType, true);
  const srResponse = useNewSR(template);
  return {
    srId,
    sr: srResponse,
    error: srResponse?.error?.response,
    template,
  };
}

export function useSaveSR(srId) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const isExistingSR = getIsExistingSr(srId);
  const onSuccess = (updatedTicket) => {
    if (updatedTicket) {
      if (isExistingSR) {
        if (updatedTicket.data?.sr) {
          queryClient.setQueryData([QUERIES_KEYS.SR_ID, String(srId)], () => updatedTicket.data.sr);
        }
      } else if (updatedTicket.sr?.id && updatedTicket.sr?.ticketTemplate?.id) {
        queryClient.setQueryData([QUERIES_KEYS.SR_ID, String(updatedTicket.sr.id)], () => updatedTicket.sr);
      }
    }
  };

  const handleSaveStatus = (data) => {
    dispatch(setIsSrSaved({ status: data?.status || 200 }));
  };
  const create = useMutation(createSR, {
    onSuccess,
    onSettled: handleSaveStatus,
    onError: () => {
      createSR({ status: 200 });
    },
  });

  const update = useMutation(
    async (updateSrObj) => {
      const data = await updateSR(updateSrObj);
      if (data?.data?.sr) {
        await queryClient.setQueryData([QUERIES_KEYS.SR_ID, String(srId)], data.data.sr);
      }
      return data;
    },
    {
      onSuccess,
      onSettled: (data) => {
        handleSaveStatus(data);
        if (data.status < 200 || data.status > 300) {
          updateSR({ status: 200 });
        }
      },
    },
  );
  return isExistingSR ? update : create;
}

export const handleRequestUserFields = ({ srObject = {}, associatedFields }) => {
  if (!Object.keys(srObject)?.length) return [];
  const requestUserSrFields = Object.keys(srObject)?.filter((key) => key.startsWith(CONSTANTS.REQUEST_USER_PREFIX));
  if (!requestUserSrFields?.length) return {};
  const { requestUser } = associatedFields || {};
  const requestUserFieldsMap = requestUserSrFields?.reduce((acc, field) => {
    const fieldName = field?.split('.')[1]; // Extract the part after 'requestUser.'
    acc[field] = requestUser?.[fieldName];
    return acc;
  }, {});
  if (associatedFields?.assetId) {
    requestUserFieldsMap.assetId = associatedFields.assetId;
  }
  if (associatedFields?.ciId) {
    requestUserFieldsMap.ciId = associatedFields.ciId;
  }
  return requestUserFieldsMap;
};

export const useUpdateAssociatedFields = ({ srObject = {}, updateObj, key }) => {
  const updatedSRValues = useMemo(() => {
    if (updateObj) {
      // temporary solution until fixed in mocks api
      const lastFieldUpdate = key || Object.keys(updateObj)[0];
      const sr = { ...srObject, ...updateObj };
      return {
        ...sr,
        lastFieldUpdate,
        computerId: sr.assetId,
        accountId: sr.accountId || localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY),
        agreement: sr.agreement?.id || sr.agreement || 1,
        requestUser: sr.requestUserName || sr.requestUser?.userName || sr.requestUser || null,
        requestUserUpdated: true,
        category: sr.category?.firstLevelKey || sr.primaryCategory || 0,
        subCategory: sr.category?.secondLevelKey || sr.secondaryCategory || 0,
        thirdLevelCategory: sr.category?.thirdLevelKey || sr.thirdLevelCategory || 0,
        requestUserManager: sr.requestUser?.userManagerName || null,
        status: sr.status ? parseInt(sr.status, 10) : 0,
      };
    }
    return null;
  }, [updateObj, key, srObject]);

  const isCategoryDrivenEnabled = useCategoryDrivenEnabled()?.data?.data;

  const enabled = !isCategoryDrivenEnabled && !isLocalHost();

  const {
    data: associatedFields,
    isError,
    ...rest
  } = useQuery({
    queryKey: [QUERIES_KEYS.ASSOCIATED_FIELDS, updatedSRValues],
    queryFn: () => newSrFieldUpdate(updatedSRValues),
    enabled: Boolean(enabled && updatedSRValues),
  });

  if ((!enabled || isError) && updateObj) {
    return { data: updateObj, isError, ...rest };
  }

  if (associatedFields) {
    const requestUserFields = handleRequestUserFields({ srObject, associatedFields });
    return { data: { ...updateObj, ...associatedFields, ...requestUserFields }, isError, ...rest };
  }

  return { data: associatedFields, isError, ...rest };
};

export function useUpdateSR() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const data = useApplicationData(QUERIES_KEYS.GENERAL_SETTINGS);
  let deescalationPromptEnabled = false;
  let manualPriorityChangesEnabled = false;
  if (data != null) {
    deescalationPromptEnabled = data.deescalationPromptEnabled;
    manualPriorityChangesEnabled = data.manualPriorityChangesEnabled;
  }
  const { mutateAsync } = useMutation(updateSR, {
    onMutate: () => {
      dispatch(setIsSaveInProgress(true));
    },
    onSuccess: (data, variables) => {
      const updateTicket = data.data.sr;
      if (updateTicket) {
        queryClient.setQueryData([QUERIES_KEYS.SR_ID, String(updateTicket.id)], (oldTicket) => {
          const shouldDeescalate = shouldShowDeEscalationPrompt(
            updateTicket,
            oldTicket,
            variables.requestParams,
            deescalationPromptEnabled,
            manualPriorityChangesEnabled,
          );
          if (shouldDeescalate) {
            dispatch(updateEscalationPrompt({ show: true, srId: updateTicket.id }));
          }

          return {
            ...oldTicket,
            ...updateTicket,
            deescalation: shouldDeescalate,
          };
        });
        // global.queueAbortController?.abort(); // cancel on going search requests
        if (variables?.requestParams?.richTextFields) {
          queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.QUEUE_DATA] });
        }
      }
      dispatch(setIsSaveInProgress(false));
    },
    onSettled: (data, error, variables) => {
      dispatch(removeUpdatingSR(variables.id));
      dispatch(
        setIsSrSaved({
          status: data.status,
          isClearRequiredField: data?.data?.params?.isClearRequiredField,
          requiredFieldsList: data?.data?.params?.fieldsList,
        }),
      );
    },
  });

  const checkAndUpdateSr = useCallback(
    async (objectToUpdate) => {
      const response = await mutateAsync(objectToUpdate);
      dispatch(addUpdatingSR(objectToUpdate.id));
      return response;
    },
    [mutateAsync, dispatch],
  );

  return { checkAndUpdateSr };
}

export function useConvertSR() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(convertSR, {
    onSuccess: (data) => {
      const convertedTicket = data?.data?.sr;
      if (convertedTicket) {
        queryClient.setQueryData([QUERIES_KEYS.SR_ID, String(convertedTicket?.id)], (oldTicket) => ({
          ...oldTicket,
          ...convertedTicket,
        }));
      }
    },
    onSettled: (data, error, variables) => {
      dispatch(removeUpdatingSR(variables.id));
    },
  });

  const convertSrType = useCallback(
    async (objectToUpdate) => {
      dispatch(addUpdatingSR(objectToUpdate.id));
      return mutateAsync(objectToUpdate);
    },
    [mutateAsync, dispatch],
  );

  return { convertSrType };
}

export function useFieldAttributes(fields = [], forFilter = false, srId = null) {
  const queryClient = useQueryClient();

  const queriesInput = useMemo(
    () =>
      fields.map((field) => {
        const {
          fieldId,
          queryForIds,
          values,
          getAll,
          srType,
          query,
          customColumn = false,
        } = convertFromClientField(field, 'fieldId');
        const queryObj = {};
        let queryKey = [QUERIES_KEYS.FIELD_ATTRIBUTE, fieldId, customColumn, forFilter, srId];

        if (getAll) {
          queryObj.getAll = getAll;
        }

        if (srType) {
          queryObj.srType = Number(srType);
        }

        if (query) {
          queryObj.query = query;
        }

        queryObj.customColumn = Boolean(customColumn);

        if (values && Array.isArray(values)) {
          queryKey = [...queryKey, values.join(',')];
        }

        queryKey = expandQueryKey(queryKey, queryObj);

        return {
          queryKey,
          queryFn: async () => {
            // For status field in filter mode, handle separately
            if (forFilter && fieldId === STATUS_FIELD_ID && !customColumn) {
              return queryClient.fetchQuery({
                queryKey: [QUERIES_KEYS.STATUS_LIST, queryObj],
                queryFn: () => getStatusListByValueClasses(queryObj),
                staleTime: QUERY_STALE_TIME.NONE,
              });
            }

            // If not querying for specific IDs or no values provided
            if (!queryForIds || !values?.length) {
              const cacheKey = [QUERIES_KEYS.FIELD_ATTRIBUTE, fieldId, queryObj];

              const options = {
                queryKey: cacheKey,
                queryFn: () => getFieldAttributes(fieldId, queryObj),
                staleTime: QUERY_STALE_TIME.NONE,
              };
              if (fieldId !== STATUS_FIELD_ID) {
                const cachedData = queryClient.getQueryData(cacheKey);
                if (cachedData) {
                  return cachedData;
                }
                options.staleTime = QUERY_STALE_TIME.DEFAULT;
              }

              return queryClient.fetchQuery(options);
            }

            // Handle cached values lookup more efficiently
            const cacheKey = [QUERIES_KEYS.FIELD_ATTRIBUTE, fieldId, customColumn, forFilter, srId];
            const cachedData = queryClient.getQueryData(cacheKey);

            if (cachedData?.values) {
              const { valuesFound, idsNotFound } = findValuesByIds(cachedData.values, values);

              if (idsNotFound.length === 0) {
                return {
                  ...cachedData,
                  values: valuesFound,
                };
              }

              // Only fetch missing values
              const newValuesPromises = idsNotFound.map((id) =>
                queryClient.fetchQuery({
                  queryKey: [QUERIES_KEYS.FIELD_ATTRIBUTE, fieldId, id, customColumn],
                  queryFn: () => getFieldAttributes(fieldId, { id, customColumn }),
                  staleTime: QUERY_STALE_TIME.DEFAULT,
                }),
              );

              const newValuesResults = await Promise.allSettled(newValuesPromises);
              const successfulResults = newValuesResults
                .filter((result) => result.status === 'fulfilled')
                .map((result) => result.value);

              // Log any failed queries
              newValuesResults
                .filter((result) => result.status === 'rejected')
                .forEach((result) => console.error('Failed to fetch field attribute:', result.reason));

              const allValues = [...valuesFound, ...successfulResults.flatMap((result) => result?.values || [])];

              return {
                ...cachedData,
                values: allValues,
              };
            }

            // If no cache hit, fetch all values in parallel
            const valuesPromises = values.map((id) =>
              queryClient.fetchQuery({
                queryKey: [QUERIES_KEYS.FIELD_ATTRIBUTE, fieldId, id, customColumn],
                queryFn: () => getFieldAttributes(fieldId, { id, customColumn }),
                staleTime: QUERY_STALE_TIME.DEFAULT,
              }),
            );

            const results = await Promise.allSettled(valuesPromises);
            const successfulResults = results
              .filter((result) => result.status === 'fulfilled')
              .map((result) => result.value);

            // Log any failed queries
            results
              .filter((result) => result.status === 'rejected')
              .forEach((result) => console.error('Failed to fetch field attribute:', result.reason));

            // Use the first successful result as the base, or create an empty object if all failed
            const baseResult = successfulResults[0] || {};
            return {
              ...baseResult,
              values: successfulResults.flatMap((result) => result?.values || []),
            };
          },
          enabled: !!fieldId,
          staleTime: QUERY_STALE_TIME.DEFAULT,
          cacheTime: QUERY_STALE_TIME.MEDIUM,
          select: (result) => {
            if (!Array.isArray(result)) return result;
            return {
              ...result[0],
              values: result?.flatMap((field) => field?.values || []),
            };
          },
          onSuccess: (data) => {
            if (!Array.isArray(data?.values) || !queryForIds) return;
            // Cache individual values for future use
            data.values.forEach((option) => {
              const value = {
                ...data,
                values: [option],
              };
              queryClient.setQueryData(
                [QUERIES_KEYS.FIELD_ATTRIBUTE, fieldId, customColumn, forFilter, `id${option.id}`],
                value,
              );
            });
          },
        };
      }),
    [fields, forFilter, queryClient, srId],
  );

  return useMemoizedQueries(queriesInput);
}

export function useStatusList(queryObj, filters = null) {
  const fieldAttributesInput = useMemo(
    () => [{ fieldId: STATUS_FIELD_ID, srType: queryObj?.srType, getAll: queryObj?.getAll, queryObj }],
    [queryObj],
  );
  const queryResult = useFieldAttributes(fieldAttributesInput)?.[0];
  let filteredStatusList = queryResult.data?.values;

  if (filters && filteredStatusList?.length > 0) {
    filteredStatusList = filteredStatusList.filter((status) =>
      Object.keys(filters).every((filter) => filters[filter] === status[filter]),
    );
  }

  const isTicketStatusClosedType = useCallback(
    (statusKey) => {
      if (queryResult.data?.values?.length > 0) {
        const foundStatus = queryResult.data.values.find((status) => status.id === statusKey);
        if (foundStatus?.valueClass === SR_PANEL_CONSTANTS.CLOSED_CLASS) {
          return foundStatus;
        }
      }
      return null;
    },
    [queryResult],
  );

  return { ...queryResult, data: filteredStatusList, isTicketStatusClosedType };
}

export function useAssigneesList(groupName, groups) {
  if (!groupName) {
    groupName = groups?.[0];
  }
  // Encode the groupName to handle special characters such as &,$, etc.
  const encodedGroupName = groupName ? encodeURIComponent(groupName) : '';
  return {
    admins: useApplicationData(QUERIES_KEYS.ADMINISTRATORS),
    groups: useApplicationData(QUERIES_KEYS.GROUPS),
    adminsByGroup: useQuery(['adminsByGroup', encodedGroupName], () => getUsersByGroup(encodedGroupName), {
      placeholderData: [],
      select: (list) => list.sort((a, b) => collator.compare(a.userName, b.userName)),
      enabled: !!encodedGroupName,
      ...options,
    }),
  };
}

export function useUserDetailsById(userId) {
  return useQuery([QUERIES_KEYS.USER_DETAILS_BY_ID, userId], () => getUserDetailsById(userId), {
    placeholderData: {},
    enabled: !!userId,
    staleTime: QUERY_STALE_TIME.MINUTE,
    cacheTime: QUERY_STALE_TIME.MEDIUM,
  });
}

export function useUserDetailsByName(userName) {
  return useQuery([QUERIES_KEYS.USER_DETAILS_BY_NAME, userName], () => getUserDetailsByName(userName), {
    placeholderData: {},
    enabled: !!userName,
    staleTime: QUERY_STALE_TIME.MEDIUM,
  });
}

const modifyFilterList = (data, listKey) => {
  if (listKey === 'requestUser') {
    const newDataWithValueName = [];
    data.forEach((item) => {
      newDataWithValueName.push({
        valueKey: item.id,
        valueCaption: `${item?.userName}`,
        valueClass: 0,
        keyName: listKey[0] + item.id.toString().replace(/\s+/g, ''),
      });
    });
    return newDataWithValueName;
  }
  const newDataWithValueName = [];
  data.forEach((item) => {
    newDataWithValueName.push({ ...item, keyName: listKey + item?.valueKey?.toString().replace(/\s+/g, '') });
  });
  return newDataWithValueName;
};

export function useColumnFilterList(listKey) {
  return useQuery([listKey], () => getColumnFilterList(listKey), {
    select: (filterList) => modifyFilterList(filterList, listKey),
    enabled: Boolean(
      listKey &&
        !(listKey === 'primaryCategory' || listKey === 'secondaryCategory' || listKey === 'thirdLevelCategory'),
    ),
    staleTime: QUERY_STALE_TIME.MEDIUM,
  });
}

export const modifyCategoriesFilter = (data, listKey) => {
  if (!data) {
    return;
  }
  const newDataWithValueName = [];
  data.forEach((item) => {
    newDataWithValueName.push({
      id: item.id,
      valueClass: 0,
      valueKey: item.id,
      valueCaption: item.value,
      name: item.value,
      keyName: listKey?.[0] + item.id.toString().replace(/\s+/g, ''),
    });
  });
  return newDataWithValueName;
};

export function useCategoriesAttributes(primaryKey, secondaryKey, srType) {
  const queryKeys = useMemo(() => [QUERIES_KEYS.CATEGORIES_ATTR, srType], [srType]);

  const options = useMemo(
    () => ({
      select: (categories) => {
        if (categories?.values?.length > 0) {
          return collatorCompare(categories.values, 'valueForFilter');
        }
        return [];
      },
      placeholderData: [],
      staleTime: QUERY_STALE_TIME.MEDIUM,
    }),
    [],
  );

  const queries = useMemo(
    () => [
      {
        queryKey: [...queryKeys, 'primaryCategory'],
        queryFn: () => getCategoryListAttributes(null, null, null, srType, 59),
        ...options,
      },
      {
        queryKey: [...queryKeys, 'secondaryCategories', primaryKey],
        queryFn: () => getCategoryListAttributes(primaryKey, null, null, srType, 60),
        ...options,
        enabled: !!primaryKey && primaryKey > -1,
      },
      {
        queryKey: [...queryKeys, 'thirdLevelCategories', primaryKey, secondaryKey],
        queryFn: () => getCategoryListAttributes(primaryKey, secondaryKey, null, srType, 61),
        ...options,
        enabled: !!(primaryKey && (secondaryKey || secondaryKey === 0) && secondaryKey > -1),
      },
    ],
    [queryKeys, primaryKey, secondaryKey, srType, options],
  );

  return useMemoizedQueries(queries);
}

export function useModifiedCategoriesAttributes(categoryType, primaryKey, secondaryKey, srType, fieldId) {
  const categories = useCategoriesAttributes(primaryKey, secondaryKey, srType, fieldId);

  const primaryCategories = categories[0]?.data;
  const secondaryCategories = categories[1]?.data;
  const thirdLevelCategories = categories[2]?.data;

  const modifiedCategoriesAttributes = useMemo(() => {
    switch (categoryType) {
      case 'primaryCategory':
        return modifyCategoriesFilter(primaryCategories, 'primaryCategory');
      case 'secondaryCategory':
        return modifyCategoriesFilter(secondaryCategories, 'secondaryCategory');
      case 'thirdLevelCategory':
        return modifyCategoriesFilter(thirdLevelCategories, 'thirdLevelCategory');
      case 'all':
        return [
          modifyCategoriesFilter(primaryCategories, 'primaryCategory'),
          modifyCategoriesFilter(secondaryCategories, 'secondaryCategory'),
          modifyCategoriesFilter(thirdLevelCategories, 'thirdLevelCategory'),
        ];
      default:
        break;
    }
  }, [categoryType, primaryCategories, secondaryCategories, thirdLevelCategories]);
  return modifiedCategoriesAttributes;
}

export function useTicketPermissions(action, srId) {
  return useQuery(
    [QUERIES_KEYS.TICKET_PERMISSIONS, action, srId],
    () => {
      if (srId) {
        return getTicketPermissions(action, srId);
      }
    },
    {
      enabled: !!srId && typeof srId === 'number',
      staleTime: QUERY_STALE_TIME.NONE,
    },
  );
}

export function buildTemplatesListForDropdown(res) {
  const response = { list: res.currentList?.slice(0, 100) || [] };
  response.values = response.list.map((t) => ({
    id: t.id,
    value: t.templateName,
  }));
  return response;
}

//might need to combine them with reduce here.. (also use forDropdown to convert)
export function useTemplateListForDropdown(sort, columnFilters, defaultsFirst) {
  return useQuery(
    [QUERIES_KEYS.TEMPLATES_LIST, sort, columnFilters, defaultsFirst],
    () =>
      getTemplatesList({
        page: 0,
        size: 100,
        columnFilters,
        sort,
        defaultsFirst,
        sourcePage: SourcePageEnum.CREATE_TICKET_PAGE,
        excludeSubTypes: true,
      }),
    {
      placeholderData: {},
      select: (res) => buildTemplatesListForDropdown(res),
    },
  );
}

export function useTicketLockStatus(srId) {
  const router = useRouter();
  const userAccount = useSelector(selectActiveUser);
  const username = userAccount?.username;
  const isExistingSR = getIsExistingSr(srId) && router.latestLocation.pathname !== ROUTES_PATHS.TEMPLATE_URL;
  return useQuery([QUERIES_KEYS.TICKET_LOCK_STATUS, Number(srId)], () => getTicketLockStatus({ username, srId }), {
    // we are sending the username to the api to add this user the viewers of this ticket, so when this ticket gets updated by another user, it will send update information in realtime (through the useChannel(sessionId, ({ data, name }) ) only to the viewers of this ticket for permissions and sensitive information purposes. this logic does not have anything to do with get locking status, but we are doing this to avoid 2 api calls on first render of a ticket. we need to refactor the name of this hook and service to make it more clear that it's not only for locking.
    placeholderData: { isLocked: false },
    enabled: isExistingSR,
    select: (data) => ({
      isLocked: data?.isLocked || data?.ticketLock, // ticketLock in case of first fetch from server, isLocked in case of cache
      lockingUser: data?.lockingUser || data?.lockUsername,
    }),
  });
}

export function useHandleTicketLock(srId) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const userAccount = useSelector(selectActiveUser);
  const lockingUser = userAccount?.username;
  const isExistingSR = getIsExistingSr(srId) && router.latestLocation.pathname !== ROUTES_PATHS.TEMPLATE_URL;
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const mutation = useMutation(lockSR, {
    onSuccess: () => {
      if (srId) {
        queryClient.setQueryData([QUERIES_KEYS.TICKET_LOCK_STATUS, Number(srId)], () => ({
          isLocked: true,
          lockingUser,
        }));
      }
    },
  });
  const { mutate, mutateAsync, ...rest } = mutation;
  const newMutate = (...params) => {
    if (isExistingSR && !lockingDetails?.isLocked) {
      mutate(...params);
    }
  };
  const newMutateAsync = (...params) => {
    if (isExistingSR && !lockingDetails?.isLocked) {
      return mutateAsync(...params);
    }
    return Promise.resolve();
  };
  return { mutate: newMutate, mutateAsync: newMutateAsync, ...rest };
}

export function useHandleTicketUnlock(srId, cb) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const userAccount = useSelector(selectActiveUser);
  const isExistingSR = getIsExistingSr(srId) && router.latestLocation.pathname !== ROUTES_PATHS.TEMPLATE_URL;
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const mutation = useMutation(unlockSR, {
    onSuccess: () => {
      if (srId) {
        queryClient.setQueryData([QUERIES_KEYS.TICKET_LOCK_STATUS, Number(srId)], () => ({
          isLocked: false,
          lockingUser: null,
        }));
      }
    },
    onSettled: () => {
      if (cb) {
        cb();
      }
    },
  });
  const { mutate, mutateAsync, ...rest } = mutation;
  const newMutate = (...params) => {
    const { forceUnlock } = params[0];
    if (
      forceUnlock ||
      (isExistingSR &&
        lockingDetails &&
        lockingDetails.isLocked &&
        lockingDetails.lockingUser === userAccount?.username)
    ) {
      mutate(...params);
    }
  };
  const newMutateAsync = (...params) => {
    if (
      isExistingSR &&
      lockingDetails &&
      lockingDetails.isLocked &&
      lockingDetails.lockingUser === userAccount?.username
    ) {
      return mutateAsync(...params);
    }
    return Promise.resolve();
  };
  return { mutate: newMutate, mutateAsync: newMutateAsync, ...rest };
}

const useDependantFields = (dependantFields, fields) => {
  const prevSourceFieldsRef = useRef(new Map());

  const dependantFieldsMap = useMemo(() => {
    const df = Array.isArray(dependantFields)
      ? dependantFields.reduce((acc, [key, value]) => {
          if (!acc[key]) {
            acc[key] = value;
          }
          return acc;
        }, {})
      : dependantFields ?? {};

    return Object.entries(df)
      .map((entry) =>
        entry.map((fieldKey) => {
          const fieldId = fieldKey?.match(/CustomColumn(\d+)/)?.[1];

          return fieldId
            ? fields?.find((field) => field.customColumn && parseInt(field.fieldId, 10) === +fieldId)?.fieldName
            : fieldKey;
        }),
      )
      .reduce((acc, [key, value]) => {
        if (key && value) {
          acc[value] = acc[value] || [];
          acc[value].push(key);
        }

        return acc;
      }, {});
  }, [fields, dependantFields]);
  return { prevSourceFieldsRef, dependantFieldsMap };
};

export const useLinkedSrsLists = ({ srs = [] }) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(true);
  // get all templates as a flatmap
  const templates = srs?.flatMap((sr) => sr?.template)?.filter(Boolean) ?? [];
  const headerFields = templates.flatMap((template) => template?.header ?? []);
  const sectionFields = templates.flatMap((template) =>
    template.sections.map((section) => section.sectionRows.flatMap((row) => row.fields)),
  );
  const dependantFields = templates.flatMap((template) => {
    if (!template?.dependantFields) return [];
    return Object.entries(template.dependantFields);
  });

  const fields = [...headerFields, ...sectionFields, ...dependantFields].filter(
    (field, index, self) => index === self.findIndex((f) => f?.fieldId === field?.fieldId),
  );

  // Create a ref to store the current field filters
  const fieldFiltersRef = useRef({});

  // Get all fields including the ones with filters
  const allFields = useMemo(() => {
    const baseFields = fields || [];
    const fieldsWithFilters = Object.entries(fieldFiltersRef.current).map(([fieldId, filter]) => ({
      fieldId,
      query: filter,
      customColumn: fields.find((f) => f.fieldId === fieldId)?.customColumn,
    }));
    return [...baseFields, ...fieldsWithFilters];
  }, [fields]);

  const fieldAttributes = useFieldAttributes(allFields);

  // Set loading state based on fieldAttributes loading state
  useEffect(() => {
    const isFieldAttributesLoading = fieldAttributes.some((attr) => attr.isLoading);
    if (isFieldAttributesLoading) setIsLoading(true);
  }, [fieldAttributes]);

  const { prevSourceFieldsRef, dependantFieldsMap } = useDependantFields(dependantFields, fields);

  const loadLinkedValues = useCallback(
    ({ updateObj, srId }) => {
      const [sourceFieldName, sourceValueId] = Object.entries(updateObj || {})[0];
      const dependantFields = (dependantFieldsMap[sourceFieldName] || []).map((fieldName) =>
        fields?.find((field) => field?.fieldName === fieldName),
      );

      if (!dependantFields.length) return;

      const sourceFieldValue = fieldAttributes
        ?.find((attr) => attr?.data?.fieldName === sourceFieldName)
        ?.data?.values?.find((value) => String(value?.id) === String(sourceValueId))?.value;

      setIsLoading(true);

      const updatePromises = dependantFields.map(async (dependantField) => {
        const dependantFieldId = dependantField?.fieldId;

        fieldFiltersRef.current = {
          ...fieldFiltersRef.current,
          [dependantFieldId]: sourceFieldValue,
        };

        // Find the field attributes for this dependant field
        const newDependantFieldAttributes = fieldAttributes.find(
          (attr) => attr?.data?.fieldId === dependantFieldId && attr?.data?.query === sourceFieldValue,
        );

        const sr = srs.find((s) => s.id === srId);

        const baseQueryKey = [QUERIES_KEYS.FIELD_ATTRIBUTE, dependantFieldId, true, false];

        // Create array of queries to update based on template status
        const queriesToUpdate = !sr
          ? [
              {
                queryKey: [...baseQueryKey, null, 'customColumn', true],
                data: newDependantFieldAttributes?.data,
              },
            ]
          : [
              {
                queryKey: [...baseQueryKey, sr.id, `srType${sr.srType}`, 'customColumn', true],
                data: newDependantFieldAttributes?.data,
              },
              {
                queryKey: [...baseQueryKey, sr.id, 'customColumn', true],
                data: newDependantFieldAttributes?.data,
              },
            ];

        // Update all queries at once
        queryClient.setQueriesData(queriesToUpdate);
      });

      Promise.all(updatePromises).finally(() => {
        setIsLoading(false);
      });
    },
    [dependantFieldsMap, fieldAttributes, fields, queryClient, srs],
  );

  useEffect(() => {
    const sourceFields = fields?.filter((field) => Object.keys(dependantFieldsMap).includes(field.fieldName));

    srs.forEach((sr) => {
      sourceFields?.forEach((sourceField) => {
        const fieldName = sourceField.fieldName;
        const fieldValue = get(sr, fieldName) || null;

        if (prevSourceFieldsRef.current?.get(`${sr.id}-${fieldName}`) !== fieldValue) {
          loadLinkedValues({ updateObj: { [fieldName]: fieldValue }, srId: sr.id });
          prevSourceFieldsRef.current?.set(`${sr.id}-${fieldName}`, fieldValue);
        }
      });
    });
  }, [fields, dependantFieldsMap, loadLinkedValues, srs, prevSourceFieldsRef]);

  return { loadLinkedValues, isLoading };
};

export function useQueueData(options) {
  const totalServiceRequest = useRef(null);
  const searchSrParams = useSelector(selectSearchSrParams);
  const prefetchSrParams = useSelector(selectPrefetchSrParams);
  const selectedTickets = useSelector(selectedQueueTickets);

  const prefetchSearchSrParams = useMemo(
    () =>
      searchSrParams && prefetchSrParams?.startRow && prefetchSrParams?.endRow
        ? {
            ...searchSrParams,
            startRow: prefetchSrParams.startRow,
            endRow: prefetchSrParams.endRow,
          }
        : null,
    [searchSrParams, prefetchSrParams],
  );

  const searchSrRequest = getRequestFromParams(searchSrParams, selectedTickets);
  const prefetchSearchSrRequest = getRequestFromParams(prefetchSearchSrParams, selectedTickets);

  const queryFn = async () => {
    global.queueAbortController = new AbortController();
    const response = await postSearchSrRequest(searchSrRequest, global.queueAbortController?.signal);
    return response;
  };

  const prefetchQueryFn = useCallback(async () => {
    global.queueAbortController = new AbortController();
    const response = await postSearchSrRequest(prefetchSearchSrRequest, global.queueAbortController?.signal);
    return response;
  }, [prefetchSearchSrRequest]);

  const queryKey = useMemo(() => getQueryKeyFromParams(searchSrParams), [searchSrParams]);
  const prefetchQueryKey = useMemo(() => getQueryKeyFromParams(prefetchSearchSrParams), [prefetchSearchSrParams]);

  const queueData = useQuery(queryKey, {
    queryFn,
    enabled: Boolean(searchSrParams && Object.keys(searchSrParams).length > 0),
    refetchOnWindowFocus: false,
    staleTime: QUERY_STALE_TIME.MINUTE,
    cacheTime: QUERY_STALE_TIME.MEDIUM,
    refetchOnMount: false,
    select: useCallback(
      (queueData) => ({
        ...queueData,
        columnConfiguration: queueData.columnConfiguration.map((col) => convertClientColumnConfig(col)),
      }),
      [],
    ),
    ...options,
  });

  const { data } = queueData;

  totalServiceRequest.current = totalServiceRequest.current ?? data?.countTotal;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      prefetchSearchSrParams &&
      Object.keys(prefetchSearchSrParams).length > 0 &&
      (totalServiceRequest.current === null || prefetchSearchSrParams.startRow < totalServiceRequest.current)
    ) {
      queryClient.prefetchQuery({
        queryKey: prefetchQueryKey,
        queryFn: prefetchQueryFn,
        staleTime: QUERY_STALE_TIME.SHORT,
        select: (queueData) => ({
          ...queueData,
          columnConfiguration: queueData.columnConfiguration.map((col) => convertClientColumnConfig(col)),
        }),
        ...options,
      });
    }
  }, [prefetchSearchSrParams, prefetchQueryKey, prefetchQueryFn, queryClient, options]);

  return queueData;
}

export function useTicketsLockMap() {
  const query = useQueueData();
  const data = useMemo(() => query?.data?.ticketsLockMap, [query?.data?.ticketsLockMap]);
  return { data };
}

export function useLoadedSrIds() {
  const query = useQueueData();
  const data = useMemo(
    () => query?.data?.serviceRequests?.map((item) => item[0].value) ?? [],
    [query?.data?.serviceRequests],
  );
  return { data };
}

export function useLoadedSrIdsWithValue(selectValueKey) {
  const generateResponse = useCallback(
    (queueData) =>
      queueData.serviceRequests?.reduce((result, item) => {
        const keyVal = item[0].value;
        const value = item.find((col) => col.field === selectValueKey)?.value;
        result[keyVal] = value;
        return result;
      }, {}),
    [selectValueKey],
  );
  const query = useQueueData();
  const data = useMemo(() => (query?.data ? generateResponse(query?.data) : null), [generateResponse, query?.data]);
  return { data };
}

export function useQueueCountTotal() {
  const query = useQueueData();
  const data = useMemo(() => query?.data?.countTotal, [query?.data?.countTotal]);
  return { data };
}
export function useSrTypeFilterCounters() {
  const query = useQueueData();

  const data = useMemo(
    () =>
      query?.data
        ? { ...query.data.srTypeFilterCounter, ALL: query.data.srTypeFilterCounterAll }
        : {
            ALL: undefined,
          },
    [query?.data],
  );

  return { data };
}

export function useIsLastRowWithData() {
  const query = useQueueData();
  const data = useMemo(
    () => (query?.data?.serviceRequests?.length ? query.data.serviceRequests.length > 0 : undefined),
    [query?.data?.serviceRequests?.length],
  );
  return { data };
}

export const useMigrationFailedHandler = () => {
  const dispatch = useDispatch();
  const { migrationFailedMessage } = useTexts();
  const [classicSRUrl, setClassicSRUrl] = useState('');

  const handleMigrationFailed = (searchString) => {
    // Dispatch toaster message
    dispatch(
      setToasterMessage({
        message: migrationFailedMessage,
        type: 'error',
        styleDialogOverrides: { zIndex: '1301' },
      }),
    );

    // Set URL for classic SR
    setClassicSRUrl(TICKETS_OPEN_URL.CLASSIC(searchString));
  };

  const handleModalClose = () => setClassicSRUrl('');

  return { classicSRUrl, handleMigrationFailed, handleModalClose };
};

export const useAiTitleAndCategories = () =>
  useMutation({
    mutationFn: getSuggestedCategoriesByText,
    onSuccess: (data) => data?.data || {},
  });

export const useLinkedSrLists = ({ sr, fields, dependantFields, updateSrFunc }) => {
  const queryClient = useQueryClient();
  const isTemplate = !sr;
  const fieldAttributes = useFieldAttributes(fields);
  const { prevSourceFieldsRef, dependantFieldsMap } = useDependantFields(dependantFields, fields);

  const loadLinkedValues = useCallback(
    ({ updateObj }) => {
      const [sourceFieldName, sourceValueId] = Object.entries(updateObj || {})[0];
      const dependantFields = (dependantFieldsMap[sourceFieldName] || []).map((fieldName) =>
        fields?.find((field) => field?.fieldName === fieldName),
      );

      if (!dependantFields.length) return;

      const sourceFieldValue = fieldAttributes
        ?.find((attr) => attr?.data?.fieldName === sourceFieldName)
        ?.data?.values?.find((value) => String(value?.id) === String(sourceValueId))?.value;

      const processField = async (dependantField) => {
        const dependantFieldId = parseInt(dependantField?.fieldId, 10);
        const dependantFieldName = dependantField?.fieldName;
        const newDependantFieldAttributes = await getFieldAttributes(dependantFieldId, {
          customColumn: dependantField.customColumn,
          fieldFilter: sourceFieldValue,
        });
        const newDependantFieldValues = newDependantFieldAttributes?.values;

        const dependantFieldValue = !sr
          ? fields?.find((field) => field?.fieldName === dependantFieldName)?.defaultValue
          : sr[dependantFieldName];

        const isDependantValueExistInList = newDependantFieldValues?.some((field) => field.id === dependantFieldValue);

        if (dependantFieldValue && !isDependantValueExistInList) {
          const objectToUpdate = { [dependantFieldName]: null };
          if (updateSrFunc) await updateSrFunc(objectToUpdate);
        }

        const baseQueryKey = [QUERIES_KEYS.FIELD_ATTRIBUTE, dependantFieldId, true, false];

        // Create array of queries to update based on template status
        const queriesToUpdate = !sr
          ? [
              {
                queryKey: [...baseQueryKey, null, 'customColumn', true],
                data: newDependantFieldAttributes,
              },
            ]
          : [
              {
                queryKey: [...baseQueryKey, sr?.id, `srType${sr?.srType}`, 'customColumn', true],
                data: newDependantFieldAttributes,
              },
              {
                queryKey: [...baseQueryKey, sr?.id, 'customColumn', true],
                data: newDependantFieldAttributes,
              },
            ];

        // Update all queries at once
        queryClient.setQueriesData(queriesToUpdate);
      };

      dependantFields.forEach(processField);
    },
    [dependantFieldsMap, fieldAttributes, fields, queryClient, sr, updateSrFunc],
  );

  useEffect(() => {
    const sourceFields = fields?.filter((field) => Object.keys(dependantFieldsMap).includes(field.fieldName));

    sourceFields?.forEach((sourceField) => {
      const fieldName = sourceField.fieldName;
      const fieldValue = (isTemplate ? sourceField.defaultValue : get(sr, fieldName)) || null;

      if (prevSourceFieldsRef.current?.get(fieldName) !== fieldValue) {
        loadLinkedValues({ updateObj: { [fieldName]: fieldValue } });
        prevSourceFieldsRef.current?.set(fieldName, fieldValue);
      }
    });
  }, [fields, dependantFieldsMap, loadLinkedValues, isTemplate, sr, prevSourceFieldsRef]);

  return { loadLinkedValues };
};

const buildSrsDataForDropdown = (data) => {
  const newData = data?.map((item) => ({
    id: item?.id,
    value: item?.title,
    srType: item?.srType,
    valueKey: item?.id,
    srTypeString: capitalizeFirstLetter(SR_TYPE_CODE_MAP[item?.srType]),
    valueCaption: `${item?.id} ${item?.title}`, // being used to filter the multi-select
    chipCaption: `${capitalizeFirstLetter(SR_TYPE_CODE_MAP[item?.srType])} #${item?.id}`,
  }));
  return newData;
};

export function useGetRecentlyCreatedSrs(srId, itemType) {
  const [hasFetched, setHasFetched] = useState(false);

  const { data, refetch } = useQuery([QUERIES_KEYS.RECENTLY_CREATED_SR, srId], () => getRecentlyCreatedSrs(srId), {
    placeholderData: [],
    select: (data) => buildSrsDataForDropdown(data),
    staleTime: 60 * 1000,
    cahceTime: 0, // API to be executed every time when linking items so the newly related items won't appear in the list OR deleted ones will appear
    enabled: itemType === ITEM_TYPES.SR.id,
  });

  useEffect(() => {
    if (itemType === ITEM_TYPES.SR.id && !hasFetched) {
      refetch().then(() => setHasFetched(true));
    }
  }, [itemType, refetch, hasFetched]);

  return { data };
}

export function useArchiveSR(getTicketAuditLogs) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: archiveSR,
    onSuccess: (_, { srId, archive }) => {
      queryClient.setQueryData([QUERIES_KEYS.SR_ID, String(srId)], (oldTicket) => ({
        ...oldTicket,
        archive: archive ? 1 : 0,
      }));

      if (getTicketAuditLogs) {
        getTicketAuditLogs();
      }
    },
  });

  return { archiveSR: mutateAsync };
}

export function useDuplicateSR(getTicketAuditLogs) {
  const { mutateAsync } = useMutation({
    mutationFn: duplicateSR,
    onSuccess: () => {
      if (getTicketAuditLogs) {
        getTicketAuditLogs();
      }
    },
  });

  return { duplicateSR: mutateAsync };
}
