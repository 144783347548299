import { Subject, Observable } from 'rxjs';
import { CONSTANTS } from './constants';
import serviceRegistry, { Service } from './ServiceRegistry';

const subject$ = new Subject<Date>();
let interval: NodeJS.Timeout | undefined;
let timeout: NodeJS.Timeout | undefined;

// Service identifier for registry
const SERVICE_ID = 'timer-service';

const getIntervalTime = (): void => {
  const currentDate = new Date();
  const currentTime = currentDate.getTime();
  const startTime = currentDate.setSeconds(0, 0);
  const startTimer = CONSTANTS.MINUTE - (currentTime - startTime);

  timeout = setTimeout(() => {
    subject$.next(new Date());
    interval = setInterval(() => {
      subject$.next(new Date());
      if (timeout) {
        clearTimeout(timeout);
      }
    }, CONSTANTS.MINUTE);
  }, startTimer);
};

interface TimerService extends Service {
  isTimerActive: boolean;
  init: () => void;
  register: () => Observable<Date>;
  clear: () => void;
  destroy: () => void;
}

export const timerService: TimerService = {
  isTimerActive: false,
  init: () => {
    timerService.isTimerActive = true;
    getIntervalTime();
    // Register with ServiceRegistry when initialized
    serviceRegistry.register(SERVICE_ID, {
      destroy: () => {
        timerService.clear();
        subject$.complete();
      },
    });
  },
  register: () => subject$.asObservable(),
  clear: () => {
    if (interval) {
      clearInterval(interval);
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    timerService.isTimerActive = false;
  },
  // Cleanup method for complete resource disposal
  destroy: () => {
    timerService.clear();
    subject$.complete();
    serviceRegistry.cleanupService(SERVICE_ID);
  },
};
