import { useQuery } from '@tanstack/react-query';
import { getAIConfigData } from 'services/sysaiService';
import { QUERIES_KEYS } from 'constant';
import { useApplicationData } from './applicationHooks';

/**
 * Default assistive AI configuration with all features enabled
 * @type {Object.<string, boolean>}
 */
const DEFAULT_ASSISTIVE_AI = {
  AICaseSummarizations: true,
  AISuggestedCategory: true,
  AIAutoPopulate: true,
  AIEmotions: true,
  AIAuthor: true,
};

/**
 * Processes and selects AI configuration data based on the provided key
 * @param {Object} data - The raw AI configuration data
 * @param {string} configDataKey - The key identifying which configuration to retrieve
 * @returns {any} The selected configuration data
 */
export const selectConfigData = (data, configDataKey) => {
  /**
   * Processes assistive AI configuration based on organization settings
   * @param {Object} data - The raw configuration data
   * @returns {Object} Processed assistive AI configuration
   */
  const processAssistiveAI = (data) => {
    let assistiveAi = { ...DEFAULT_ASSISTIVE_AI };

    if (data.orgConf?.AssistiveAI) {
      if (data.orgConf.AssistiveAI.Enable) {
        assistiveAi = data.orgConf.AssistiveAI;
      } else {
        Object.keys(assistiveAi).forEach((key) => {
          assistiveAi[key] = false;
        });
      }
    }
    return assistiveAi;
  };

  /**
   * Mapping of configuration keys to their corresponding data selectors
   * @type {Object.<string, function(Object, Object): any>}
   */
  const configDataHandlers = {
    [QUERIES_KEYS.IS_AI_SUMMARIZATIONS]: (data, assistiveAi) => assistiveAi.AICaseSummarizations,
    [QUERIES_KEYS.IS_AI_SUGGESTED_CATEGORY]: (data, assistiveAi) => assistiveAi.AISuggestedCategory,
    [QUERIES_KEYS.IS_AI_AUTO_POPULATE]: (data, assistiveAi) => assistiveAi.AIAutoPopulate,
    [QUERIES_KEYS.IS_AI_EMOTIONS]: (data, assistiveAi) => assistiveAi.AIEmotions,
    [QUERIES_KEYS.IS_AI_AUTHOR]: (data, assistiveAi) => assistiveAi.AIAuthor,
    [QUERIES_KEYS.ASSISTIVE_AI]: (data, assistiveAi) => assistiveAi,
    [QUERIES_KEYS.IS_AI_ADMINISTRATOR]: (data) => data.isAdministrator,
  };

  const assistiveAi = processAssistiveAI(data);
  const handler = configDataHandlers[configDataKey];
  return handler ? handler(data, assistiveAi) : data.orgConf;
};

/**
 * Hook for fetching and managing AI configuration data
 * @param {string} configDataKey - The key identifying which configuration to retrieve
 * @returns {import('@tanstack/react-query').UseQueryResult} The query result object containing the selected AI configuration
 */
export const useGetAIConfigData = (configDataKey) => {
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);

  return useQuery({
    queryKey: [QUERIES_KEYS.AI_CONFIG_DATA],
    queryFn: getAIConfigData,
    select: (data) => selectConfigData(data, configDataKey),
    enabled: !!isAiEnabled,
  });
};
